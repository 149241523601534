<template>
  <div>
    <button
      class="btn"
      v-if="$store.state.currentRole == 'ADMIN'"
      style="background-color: #083628; color: white;"
      @click="showAddUserModal"
    >
      Add User
    </button>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Add A User`"
            :visible.sync="addUserModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="userForm"
                :rules="rules"
                label-position="top"
                ref="userForm"
                class="demo-userForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Full Name"
                      prop="fullname"
                    >
                      <el-input v-model="userForm.fullname"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item
                      label="Email"
                      prop="email"
                    >
                      <el-input v-model="userForm.email"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Username"
                      prop="username"
                    >
                      <el-input v-model="userForm.username"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item
                      label="Password"
                      prop="password"
                    >
                      <el-input v-model="userForm.password"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Choose User Role"
                      prop="role"
                    >
                      <el-select
                        v-model="userForm.role"
                        placeholder="Select"
                        class="w-100"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item
                      label="Choose Country"
                      prop="country"
                    >
                      <el-select
                        v-model="userForm.country"
                        placeholder="Select"
                        class="w-100"
                      >
                        <el-option
                          v-for="country in countries"
                          :key="country.id"
                          :label="country.country_title"
                          :value="country.country_title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="User Photo">
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handleUserPhotoPreview"
                        :on-remove="handleUserPhotoRemove"
                        :file-list="userPhotoFileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="addUser('userForm')"
                  >Add User</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2024 Tata Africa Safaris | All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",
      selectedUserPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      userPhotoFileLists: [],
      countries: [],

      options: [
        {
          value: "AGENT",
          label: "Agent",
        },
        {
          value: "ADMIN",
          label: "Admin",
        },

        {
          value: "MARKETER",
          label: "Marketing officer",
        },
      ],

      addUserModal: false,
      editUserModal: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      userForm: {
        fullname: "",
        email: "",
        username: "",
        role: "",
        country: "",
        password: "",
      },

      rules: {
        fullname: [
          { required: true, message: "Full Name is required", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        username: [
          {
            required: true,
            message: "Username is required",
            trigger: "change",
          },
        ],
        role: [
          {
            required: true,
            message: "User Role is required",
            trigger: "change",
          },
        ],
        country: [
          {
            required: true,
            message: "Country is required",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    handleUserPhotoPreview(file) {
      console.log(file.raw);
      this.userPhotoFileLists = [file];
      this.selectedUserPhotoFile = file.raw;
    },
    handleUserPhotoRemove() {
      this.selectedUserPhotoFile = null;
    },

    showAddUserModal() {
      this.addUserModal = true;
      this.getCountries();
      this.userForm.title = "";
      this.userForm.course_unit_code = "";
      this.userForm.year_of_study = "";
      this.userForm.semester_of_study = "";
      this.userForm.course_id = this.course_id;
      this.userForm.credit_unit = "";
    },

    async addUser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("fullname", this.userForm.fullname);
            formData.append("username", this.userForm.username);
            formData.append("email", this.userForm.email);
            formData.append("image", this.selectedUserPhotoFile);
            formData.append("role", this.userForm.role);
            formData.append("country", this.userForm.country);
            formData.append("password", this.userForm.password);
            let response = await this.$http.post(`api/user/add`, formData, {
              onUploadProgress: (progressEvent) => {
                this.imageUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
            if (
              response.data.success &&
              response.data.message == "ACCOUNT_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "User added successfully",
                type: "success",
              });
              this.addUserModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to add user",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async getCountries() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/admin/get_all`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.countries = request.data.destinations;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Destinations now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>